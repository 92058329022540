html {
  //-----------------------------------------------Variable used for project styles------------------------------------
  --myPrimaryFont: Roboto;
  --myPrimaryColor: #6b50ff;
  --buttonTextColot: #ffffff;

  //-----------------------------------------------Commons------------------------------------
  *:not(i) {
    font-family: var(--myPrimaryFont) !important;
  }

  .primaryColor {
    color: var(--myPrimaryColor) !important;
  }

  button {
    color: var(--buttonTextColor) !important;
    background-color: var(--myPrimaryColor) !important;
  }

  .label {
    color: var(--myPrimaryColor) !important;
    border-color: var(--myPrimaryColor) !important;
  }

  i {
    color: var(--myPrimaryColor);
  }

  a {
    text-decoration: underline;
  }

  //this keep a mobile design style for tablet and desktop
  .ui.container {
    width: 100%;
    height: 100%;
    margin: 0 auto !important;
    padding: 1rem;
    text-align: center;

    @media screen and (min-width: 1200px) {
      width: 400px;
      border-right: 1px solid rgba(34, 36, 38, 0.15);
      border-left: 1px solid rgba(34, 36, 38, 0.15);
    }
  }

  //-----------------------------------------------FilePond style------------------------------------
  .filepond-wrapper {
    margin: 1.5rem 0;
    
    .filepond--drop-label {
      min-height: 65px;
      background-color: #ffffff;
      border: 1px solid var(--myPrimaryColor);
      border-radius: 4px;
      font-family: inherit;
      font-size: 18px;
    }

    span.filepond--label-action {
      text-decoration: none;
    }

    //Hide upload/retry button from the filepond control to force user using the "Submit" button.
    .filepond--file {
      .filepond--action-process-item,
      .filepond--action-retry-item-processing {
        display: none;
      }
    }
  }
}
